<template>
  <NewsEdit :news="news" />
</template>

<script>
import NewsEdit from "@/components/news/NewsEdit"
import { mapGetters } from "vuex"

export default {
  name: "NewsCreate",
  components: {
    NewsEdit,
  },
  computed: {
    ...mapGetters({ news: "news/currentNews" }),
  },
}
</script>

<style scoped></style>
